import React from 'react'
import Helmet from 'react-helmet'
import { SITE_TITLE } from 'src/constants'
import { stripLinkToIndex, convertArticleType2menus } from 'src/commonFunctions'
import ArticleLayout from 'components/ArticleLayout'
import InterviewArticle from 'components/InterviewArticle'
import Footer from 'components/Footer'

export default ({ pageContext }) => {
  const {
    type,
    articleBaseInfo,
    recommendArticles,
    interviewContents,
    sharePath,
    linkToIndex,
    adbanners,
  } = pageContext
  const {
    title,
    date,
    slug,
    categories,
    tags,
    ogpSettings,
    isOfficial,
  } = articleBaseInfo
  const linktoindex = stripLinkToIndex(linkToIndex)

  return (
    <>
      <Helmet title={`${title} | ${SITE_TITLE}`} />
      <ArticleLayout
        type={type}
        articleBaseInfo={articleBaseInfo}
        recommendArticles={recommendArticles}
        sharePath={sharePath}
        linkToIndex={linkToIndex}
        adbanners={adbanners}
      >
        <InterviewArticle
          genre={convertArticleType2menus(type)}
          title={title}
          date={date}
          categories={categories}
          tags={tags}
          linktoindex={linktoindex}
          slug={slug}
          ogp={ogpSettings.ogp}
          interviewContents={interviewContents}
          isOfficial={isOfficial}
          sharePath={sharePath}
        />
      </ArticleLayout>
      <Footer tags={pageContext.tags} />
    </>
  )
}
